
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import {
  getMessageList,
  initMessageData,
  MessageData,
  cancelSend,
} from '@/api/operation/message_push';
import { PaginationData } from '@/api/operation/customer';
import moment, { Moment } from 'moment';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
interface Form {
  message: string;
  message_type: number;
  jump_type: number;
  status: number;
  time: Moment[];
}
function initForm(): Form {
  return {
    message: '',
    message_type: 0,
    jump_type: 0,
    status: 0,
    time: [],
  };
}
const MESSAGE_TYPE = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '图文链接',
  },
  {
    id: 2,
    value: '文本链接',
  },
  {
    id: 3,
    value: '纯文本',
  },
];
const JUMP_TYPE = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '内部跳转',
  },
  {
    id: 2,
    value: '外部跳转',
  },
];
const STATUS = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '待发送',
  },
  {
    id: 2,
    value: '已发送',
  },
  {
    id: 3,
    value: '已取消',
  },
];
@Component({
  name: 'MessagePush',
})
export default class MessagePush extends Vue {
  setGlobalFunction = useGlobalFunction().setGlobalFunction;
  cancel_send_id: number = -1;
  form_filter_data: Form = initForm();
  form_item_layout: any = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  is_loading: boolean = false;
  is_show_cancel_send_modal: boolean = false;
  message_data: PaginationData<MessageData> = initMessageData();
  get messageType() {
    return MESSAGE_TYPE;
  }
  get jumpType() {
    return JUMP_TYPE;
  }
  get status() {
    return STATUS;
  }
  showCancelSendModal(id: number) {
    this.is_show_cancel_send_modal = true;
    this.cancel_send_id = id;
  }
  highlight(item) {
    return item.replace(/<[^>]+>/g, '');
  }
  // 去编辑页面
  goEditPage(item: any) {
    this.$router.push(`/operation/add-message?id=${item.id}`);
  }
  // 点击查看按钮
  viewMessage(item: any) {
    this.$router.push(`/operation/add-message?id=${item.id}&is_view=1`);
  }
  paginationOption(data: any) {
    return {
      current: data.current || 0,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }
  @changeLoading(['is_loading'])
  async handleChange(data: any) {
    const start_time = this.form_filter_data.time[0]
      ? Math.floor(+moment(this.form_filter_data.time[0]).startOf('day').format('x') / 1000)
      : '';
    const end_time = this.form_filter_data.time[1]
      ? Math.floor(+moment(this.form_filter_data.time[1]).endOf('day').format('x') / 1000)
      : '';
    const query_params = {
      search: this.form_filter_data.message,
      type: this.form_filter_data.message_type === 0 ? '' : this.form_filter_data.message_type,
      jump_type: this.form_filter_data.jump_type === 0 ? '' : this.form_filter_data.jump_type,
      status: this.form_filter_data.status === 0 ? '' : this.form_filter_data.status,
      start_time: start_time,
      end_time: end_time,
      current: data.current,
    };
    await this.getMessageList(query_params);
  }
  // 确认取消发送
  @changeLoading(['is_loading'])
  async cancelSend() {
    const res: any = await cancelSend(this.cancel_send_id);
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.is_show_cancel_send_modal = false;
    this.$message.success('取消发送成功');
    await this.getMessageList();
  }
  // 搜索消息
  @changeLoading(['is_loading'])
  async searchMessage() {
    const start_time = this.form_filter_data.time[0]
      ? Math.floor(+moment(this.form_filter_data.time[0]).startOf('day').format('x') / 1000)
      : '';
    const end_time = this.form_filter_data.time[1]
      ? Math.floor(+moment(this.form_filter_data.time[1]).endOf('day').format('x') / 1000)
      : '';
    const query_params = {
      search: this.form_filter_data.message,
      type: this.form_filter_data.message_type === 0 ? '' : this.form_filter_data.message_type,
      jump_type: this.form_filter_data.jump_type === 0 ? '' : this.form_filter_data.jump_type,
      status: this.form_filter_data.status === 0 ? '' : this.form_filter_data.status,
      start_time: start_time,
      end_time: end_time,
    };
    await this.getMessageList(query_params);
  }
  // 清空搜索条件
  clearForm() {
    this.form_filter_data = initForm();
    this.searchMessage();
  }
  // 获取消息列表
  @changeLoading(['is_loading'])
  async getMessageList(query_params?: any) {
    const res = await getMessageList(query_params);
    this.message_data = initMessageData();
    this.message_data = initMessageData(res.data);
  }
  @changeLoading(['is_loading'])
  async created() {
    await this.getMessageList();
    await this.setGlobalFunction({ getMessageList: this.getMessageList });
  }
  changeTime(val) {
    return moment(val).format('YYYY-MM-DD HH:mm:ss');
  }
}
